.contenedor{
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
    color: #333;

}
.container {
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .header {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #1c2434;
    border-radius: 10px;
    color: #fff;
  }
.header1{
    text-align: center;
    color: #333;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
.header2{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
.grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
.orderSummary, .comments, .paymentInfo, .customerInfo, .creditCardInfo {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}
.orderSummary .header2, .comments .header2, .paymentInfo .header2, .customerInfo .header2, .creditCardInfo .header2 {
    margin-top: 0;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}
.th, .td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}
.th {
    background-color: #f2f2f2;
}
.orderTotal {
    text-align: right;
    font-weight: bold;
}
.label {
    display: block;
    margin: 10px 0 5px;
    color: #333;    
}
input[type="text"], input[type="password"], input[type="email"], input[type="tel"],select, textarea {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
textarea {
    resize: vertical;
}
.buttonConfirm {
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}
.buttonConfirm:hover {
    background-color: #555;
}

@media (min-width: 600px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .buttonConfirm {
        width: 30%;
        margin: 20px auto;
        display: block;
    }
}

.error {
    border: 1px solid red;
  }



  .buttonConfirm:disabled {
    background-color: #ccc; /* Cambiar el color del fondo */
    cursor: not-allowed;   /* Mostrar el cursor de "no permitido" */
    opacity: 0.7;          /* Reducir la opacidad */
    display: none;
  }