/* Sección de contacto */
.contactSection {
    background-color: #f8f9fa;
    color: #1c2434;
    padding: 80px 20px;
    display: flex;
    justify-content: center;
}

.contactContent {
    max-width: 1200px;
    width: 100%;
    text-align: center;
}

.contactSection h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
}
.contactHeader {
    margin-bottom: 40px;
}

/* Título de contacto */
.contactSection h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
}
.contactIntro {
    font-size: 1.1rem;
    margin-bottom: 40px;
    max-width: 700px;
    margin: 0 auto;
    color: #666;
}

/* Contenedor para la información y el formulario */
.contactContainer {
    display: flex;
    gap: 50px;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
}

/* Información de contacto */
.contactInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 320px;
    text-align: left;
}

.contactItem h3 {
    font-size: 1.2rem;
    color: #333;
}

.contactItem p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0 0;
}

.contactItem a {
    font-size: 1rem;
    color: #1c2434;
    text-decoration: none;
}

.contactItem a:hover {
    text-decoration: underline;
}

/* Formulario de contacto */
.contactForm {
    background-color: #1c2434;
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 600px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between; /* Asegura que el formulario ocupe toda la altura disponible */
}

.contactForm h5 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #fff;
}

.contactForm label {
    display: block;
    font-size: 1rem;
    color: #ccc;
    margin-bottom: 8px;
}

.contactForm input,
.contactForm textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    background-color: #1c2434;
    box-sizing: border-box;
}

.contactForm input:focus,
.contactForm textarea:focus {
    border-color: #fff;
    outline: none;
}

.contactButton {
    padding: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #1c2434;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.contactButton:hover {
    background-color: #1c2434;
    color: #fff;
}

/* Responsividad */
@media (max-width: 992px) {
    .contactContainer {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .contactInfo,
    .contactForm {
        max-width: 100%;
    }
}

.contactSocialItem {
    grid-column-gap: 10px;
    display: flex;
    text-decoration: none;
    color: #1c2434;
}

.contactSocial {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    display: flex;
}


.contactCall, .contactEmail {
    display: block;
}

@media screen and (max-width: 479px) {
    .contactForm {
        padding: 30px 40px 0px 30px;
    }
    
    .contactForm input,
    .contactForm textarea {
        padding: 10px; /* Reducir el padding en dispositivos pequeños */
        font-size: 0.9rem; /* Ajustar el tamaño de la fuente */
    }

    .contactButton {
        padding: 10px;
        font-size: 1rem; /* Ajustar el tamaño del botón */
    }
}
 
