/* Estilos para la sección del sistema */
.systemSection {
    /*  background-color: #f0f4f8;*/
      padding: 60px 20px;
      text-align: center;
  }
  
  .systemSection h2 {
      font-size: 2.8rem;
      color: #1c2434;
      font-weight: bold;
      margin-bottom: 50px;
      letter-spacing: 1px;
  }
  
  .systemContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px;
  }
  
  .systemItem {
      background: #ffffff;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      position: relative;
  }
  
  .systemItem:hover {
      transform: translateY(-8px);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .systemItem img {
      width: 100%;
      height: auto;
      display: block;
    /*  border-bottom: 3px solid #1c2434; */
  }
  
  .systemDescription {
      padding: 20px;
      color: #333;
      text-align: left;
  }
  
  .systemDescription h3 {
      font-size: 1.4rem;
      color: #1c2434;
      margin-bottom: 10px;
  }
  
  .systemDescription p {
      font-size: 1rem;
      color: #555;
      line-height: 1.5;
      margin: 0;
  }
  
  /* Responsividad para la sección del sistema */
  @media (max-width: 992px) {
      .systemContainer {
          grid-template-columns: repeat(2, 1fr);
      }
  }
  
  @media (max-width: 576px) {
      .systemContainer {
          grid-template-columns: 1fr;
      }
  }
  