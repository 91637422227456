.main {
    padding: 60px 20px;
/*    background: linear-gradient(135deg, #f8f9fa, #e9ecef);*/
 /*   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);*/
/*    border-radius: 8px; */
}

/* Contenedor principal */
.container {
    max-width: 1140px;
    margin: 0 auto;
}