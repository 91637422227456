.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
   /* border: 2px solid #1c2434;*/
  }
  
  .header {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #1c2434;
    border-radius: 10px;
    color: #fff;
  }
  
  .card {
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .estimateCard {
    background-color: #e0f7fa;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
  }
  
  .orderSummary, .orderItems, .orderEstimate {
    margin: 20px 0;
    text-align: left;
    border-top: 1px solid #ccc;
    padding-top: 15px;
  }
  
  .itemList {
    list-style: none;
    padding: 0;
  }
  
  .orderStatus {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;
}

.status-en_preparacion {
    background-color: #ffeb3b;
    color: #333;
}

.status-listo {
    background-color: #4caf50;
    color: white;
}

.status-pendiente {
    background-color: #c03a3a;
    color: white;
}

.status-entregado {
    background-color: #2196f3;
    color: white;
}

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 8px;
  }
  
  .itemQuantity {
    background-color: #1c2434;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .button {
    background-color: #1c2434;
    color: #fff;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s;
  }
  
  .button:hover {
    background-color: #2b303c;
  }
  
  .buttonSecondary {
    background-color: #f1f1f1;
    color: #333;
    border: 1px solid #ccc;
    padding: 12px 20px;
    border-radius: 8px;
  }
  
  .buttonSecondary:hover {
    background-color: #e0e0e0;
  }
  