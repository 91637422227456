/* Estilos generales del footer */
.footer {
    background-color: #000;
    color: #ffffff;
    padding: 40px 20px;
}

.footerContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    align-items: start;
    justify-content: space-between;
}

/* Estilos para el logo */
.footerLogo {
    flex: 1;
    min-width: 150px;
}

.footerLogo img {
    width: 225px;
    margin-bottom: 20px;
}

/* Estilos de columnas */
.footerColumns {
    display: flex;
    gap: 40px;
    flex: 3;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footerColumn {
    flex: 1;
    min-width: 150px;
}

.footerColumn h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #ffffff;
}

/* Estilos de enlaces */
.footerColumn ul {
    list-style: none;
    padding: 0;
}

.footerColumn li {
    margin-bottom: 10px;
}

.footerColumn a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
}

.footerColumn a:hover {
    color: #ff7f50;
}

/* Enlaces de redes sociales con íconos */
.socialLinks i {
    margin-right: 8px;
}

/* Pie de página inferior */
.footerBottom {
    margin-top: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
}

/* Responsividad */
@media (max-width: 768px) {
    .footerContainer {
        flex-direction: column;
        align-items: center;
    }

    .footerLogo {
        text-align: center;
        margin-bottom: 20px;
    }

    .footerColumns {
        flex-direction: column;
        gap: 20px;
    }

    .footerColumn {
        text-align: center;
    }
}
