/* Estilos generales para la sección de precios */
.pricingSection {
    background-color: #ffffff;
    padding: 60px 20px;
    text-align: center;
}

.pricingSection h2 {
    font-size: 2.5rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 40px;
}

/* Contenedor de la cuadrícula para las tarjetas */
.pricingContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Estilos para las tarjetas de planes */
.planCard {
    background-color: #ffffff;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 25px;
    transition: all 0.3s ease;
    text-align: center;
    color: #333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.planCard:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.planCard h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    letter-spacing: 1px;
}

.planCard .cardPrice {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 20px 0;
}

.billingPeriod {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    color: #555;
    margin-top: 5px;
}

.planCard ul {
    font-size: 1rem;
    list-style: none;
    padding: 0;
    margin: 20px 0;
    color: #555;
}

.planCard ul li {
    margin-bottom: 10px;
    padding-left: 20px; /* Espacio para los íconos */
    text-align: left;
    font-size: 15px;
}

.planCard ul li i {
 
    position: static;
 
}

/* Botón en blanco y negro dentro de las tarjetas de planes */
.planCard .btnPrimary {
    background-color: #333;
    color: #ffffff;
    border: 2px solid #333;
    border-radius: 15px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.planCard .btnPrimary:hover {
    background-color: #ffffff;
    color: #333;
}

/* Responsividad para las tarjetas de planes */
@media (max-width: 992px) {
    .pricingContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .pricingContainer {
        grid-template-columns: 1fr;
    }

    .planCard {
        padding: 20px;
    }

    .planCard h5 {
        font-size: 1.3rem;
    }

    .planCard .cardPrice {
        font-size: 1.8rem;
    }

    .planCard ul li {
        font-size: 0.9rem;
    }

    .planCard .btnPrimary {
        padding: 8px 20px;
        font-size: 0.9rem;
    }
}
