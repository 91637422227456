/* Asegura que la estructura de la página ocupe al menos toda la altura de la pantalla */
.pageContent {
    display: flex;
    flex-direction: column; /* Coloca los elementos en una columna */
    min-height: 100vh; /* Hace que el contenedor ocupe al menos toda la altura de la pantalla */
  }
  
  /* Contenedor de contenido que ocupa el espacio restante */
  .content {
    flex: 1; /* Asegura que el contenido ocupe todo el espacio disponible */
    flex-direction: column; /* Asegura que el contenido sea vertical */
  }