body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.menu.active {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1024px;
  margin: 0 auto;
}

.menu{
  padding: 20px;
}

.headers{
  padding: 20px;
}