/* Estilos para la sección de beneficios */
.benefitsSection {
    background-color: #ffffff;
    padding: 80px 20px;
    text-align: center;
    color: #1c2434;
}

.benefitsSection h2 {
    font-size: 2.8rem;
    font-weight: bold;
    color: #1c2434;
    margin-bottom: 15px;
}

.benefitsIntro {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 50px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

.benefitsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.benefitItem {
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.benefitItem:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.benefitIcon {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #1c2434;
}

.benefitItem h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1c2434;
    margin-bottom: 10px;
}

.benefitItem p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

/* Responsividad para la sección de beneficios */
@media (max-width: 992px) {
    .benefitsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .benefitsContainer {
        grid-template-columns: 1fr;
    }
}
