.menuBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--menu-color); /* Color dinámico */
    color: white;
    padding: 10px 20px;
    position: fixed; /* Fija la barra de navegación */
    top: 0; /* Asegura que esté en la parte superior */
    left: 0;
    width: 100%; /* Ocupa todo el ancho */
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional */
    height: 50px; /* Define una altura clara para facilitar el cálculo */
  }

  
  .title {
    margin: 0;
    font-size: 24px;
  }
  
  .links {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .link {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    transition: color 0.3s;
  }
  
  .cartIcon {
    font-size: 24px;
    cursor: pointer;
    display: flex;               
    align-items: center;         
    position: relative;
    right: 30px;
  }
  
  .cartCount {
    font-size: 14px;
    color: black;
    background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 900;
  }
  