/* General styles */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9fafb; /* Fondo claro */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.title {
    font-size: 1.8rem;
    color: #1c2434; /* Color primario */
    margin-bottom: 10px;
}

.description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
}

/* Search box */
.searchBox {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.input {
    width: 300px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.input:focus {
    outline: none;
    border-color: #1c2434;
    box-shadow: 0 0 5px rgba(28, 36, 52, 0.2);
}

.button {
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1c2434; /* Color primario */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.button:hover {
    background-color: #334155; /* Color más oscuro */
}

/* Loading and error */
.loading {
    color: #1c2434;
    font-weight: bold;
}

.error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

/* Order details */
.orderDetails {
    text-align: left;
    margin-top: 20px;
    background-color: #f1f1f1; /* Fondo blanco para resaltar */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para efecto de tarjeta */
    border: 1px solid #e5e7eb; /* Borde ligero */
    font-size: 0.95rem; /* Tamaño de fuente ajustado */
}

.detailsTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #1c2434; /* Color primario */
    border-bottom: 2px solid #e5e7eb; /* Separador */
    padding-bottom: 5px;
}

.orderDetails p {
    margin: 10px 0;
    color: #444; /* Texto gris oscuro */
}

.orderDetails strong {
    color: #1c2434; /* Color destacado */
}

.itemsTitle {
    font-size: 1.2rem;
    margin-top: 20px;
    color: #1c2434; /* Color primario */
    font-weight: bold;
    border-bottom: 1px solid #e5e7eb; /* Línea separadora */
    padding-bottom: 5px;
}

.itemsList {
    list-style: none; /* Quitar viñetas */
    padding: 0;
    margin-top: 10px;
}

.item {
    display: flex;
    justify-content: space-between; /* Separar nombre y cantidad */
    padding: 8px 0; /* Espaciado entre ítems */
    border-bottom: 1px solid #f1f1f1; /* Línea divisoria */
}

.item:last-child {
    border-bottom: none; /* Quitar línea del último elemento */
}

.item span {
    font-weight: bold;
    color: #1c2434; /* Destacar cantidad */
}

.status {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    display: inline-block;
    font-size: 0.9rem;
}

.status-en_preparacion {
    background-color: #ffeeba; /* Amarillo suave */
    color: #856404; /* Texto amarillo oscuro */
}

.status-listo {
    background-color: #c3e6cb; /* Verde claro */
    color: #155724; /* Texto verde oscuro */
}

.status-entregado {
    background-color: #bee5eb; /* Azul claro */
    color: #0c5460; /* Texto azul oscuro */
}
