.tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite el ajuste automático en filas */
  gap: 10px; /* Espaciado uniforme entre los tabs */
  margin: 20px 0;
  padding: 10px;
}

.tab {
  flex: 1 1 calc(25% - 10px); /* Cada tab ocupa un 25% del ancho menos el espacio del gap */
  min-width: 120px; /* Asegura un ancho mínimo para los tabs */
  max-width: 250px; /* Limita el ancho máximo en pantallas grandes */
  padding: 10px 20px;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
}

.tab:hover {
  transform: scale(1.05); /* Efecto visual al pasar el cursor */
}

.activeTab {
  background-color: var(--menu-color);
  color: white;
}

/* Para pantallas medianas: dos tabs por fila */
@media (max-width: 768px) {
  .tab {
    flex: 1 1 calc(50% - 10px); /* Cada tab ocupa el 50% del ancho */
  }
}

/* Para pantallas pequeñas: un tab por fila */
@media (max-width: 480px) {
  .tab {
    flex: 1 1 100%; /* Cada tab ocupa el 100% del ancho */
  }
}
