/* Contenedor del formulario */
.formContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo del CardElement */
  .cardElement {
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
    color: #32325d;
  }
  
  .cardElement:focus {
    border-color: #3b82f6;
    outline: none;
  }
  
  .cardElement--invalid {
    border-color: #f87171;
  }
  
  .cardElement--complete {
    border-color: #4ade80;
  }
  
  /* Estilo del botón */
  .submitButton {
    background-color: #3b82f6;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #2563eb;
  }
  
  .submitButton:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
  
  /* Mensaje de estado */
  .message {
    margin-top: 16px;
    font-size: 14px;
    color: #374151;
  }
  
  .creditCardInfo {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}