.loadingDots {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #fff; /* Cambia esto al color primario que prefieras */
    border-radius: 50%;
    animation: loadingAnimation 0.6s infinite alternate;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loadingAnimation {
    from {
      opacity: 0.3;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  